import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Card from '../components/Card'
import appsStyles from './waker.module.scss'
import { OutboundLink } from "gatsby-plugin-google-analytics"


const Apps = () => {
    return (
        <Layout className={appsStyles.Layout}>
            <h1 className={appsStyles.title}>Waker: Visual Alarm (2018)</h1>
            <h2>Since the concept of knowing how to tell time is not something we are born with, grasping that concept at a very young age can be crucial to the sleeping well being of both parents and children. </h2>
            <p>The Waker app was designed out of our family's necessity to minimize the time between when our child woke up in the morning and when they knew it was ok for them to leave their bedroom.</p>
            <p>By using the concept of colors set to change in a timed sequence, children are able to recognize and predict the next set of colors, through repetition. Waker's unique PreWake Timer gives the child the opportunity to anticipate the next sequence of colors, that ultimately leads to the simple, yet affective, Wake Up screen.</p>
            <div className="CardGroup">
                <div className="WakerCard">
                    <Card image={require("../images/screenshots/screen-waker-2.jpg")} />
                </div>
                <div className="WakerCard">
                    <Card image={require("../images/screenshots/screen-waker-3.jpg")} />
                </div>
                <div className="WakerCard">
                    <Card image={require("../images/screenshots/screen-waker-4.jpg")} />
                </div>
                <div className="WakerCard">
                    <Card image={require("../images/screenshots/screen-waker-5.jpg")} />
                </div>
            </div>
            <OutboundLink href="https://apps.apple.com/us/app/waker-visual-alarm/id1403390894" target="_blank" rel="noopener noreferrer"><img src={require('../images/logo-appStore.png')} height="50"></img></OutboundLink>
        </Layout>
    )
}

export default Apps
